.ant-upload.ant-upload-select {
  width: 150px !important;
  height: 150px !important;
  background-color: #fff !important;
}

.ant-upload-list-item-container {
  width: 150px !important;
  height: 150px !important;
}

.ant-form-item-row {
  display: block !important;
}

.ant-form-item-label > label {
  height: 26px !important;
  color: #0c4a6e !important;
}

.ant-form-item {
  margin: 0 !important;
}

.ant-tour {
  width: 300px !important;
}

.ant-checkbox-group {
  padding-top: 8px !important;
  display: flex !important;
  flex-direction: column !important;
}

.ant-checkbox-wrapper {
  font-size: 16px !important;
  color: #64748b !important;
  font-weight: 400;
  margin-bottom: 8px !important;
}

.large-checkbox .ant-checkbox,
.large-checkbox .ant-checkbox-inner {
  width: 20px !important;
  height: 20px !important;
}

.large-checkbox .ant-checkbox-disabled {
  display: none !important;
}

.large-checkbox .ant-checkbox-wrapper-disabled span {
  color: inherit;
}

.ant-breadcrumb-link a,
.ant-breadcrumb-link a:hover {
  color: white;
}
.ant-breadcrumb-separator {
  color: white!important;
}
